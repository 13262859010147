import React, { useState } from 'react'
import PropTypes from 'prop-types'

const CheckKennin = (props) => {
  const [isChecked, setChecked] = useState(props.checked)

  const handleChange = (e) => {
    const type2 = document.getElementById(props.type2)
    if (e.target.checked) {
      type2.options[0].disabled = true
      type2.options[0].selected = false
      setChecked(true)
    } else {
      type2.options[0].disabled = false
      type2.options[0].selected = true
      setChecked(false)
    }
  }

  const Checked = () => {
    return (
      <label className='checkbox is-primary'>
        <input type='checkbox' id='kennin' checked onChange={handleChange} />
        <span className='item'>兼任</span>
      </label>
    )
  }

  const NotChecked = () => {
    return (
      <label className='checkbox is-primary'>
        <input type='checkbox' id='kennin' onChange={handleChange} />
        <span className='item'>兼任</span>
      </label>
    )
  }

  return (
    <>
      {isChecked ? <Checked /> : <NotChecked />}
    </>
  )
}

CheckKennin.propTypes = {
  type2: PropTypes.string,
  checked: PropTypes.bool
};

export default CheckKennin
