import '../../stylesheets/common/common.scss'
import '../../javascripts/admin/common.js'
import '../../javascripts/hasmany_form_base.js'
import '../../javascripts/uploader_base.js'
import '../../javascripts/dropdown_base.js'
import '../../javascripts/modal_base.js'
import '../../javascripts/disabled_form.js'

import Rails from '@rails/ujs'
Rails.start()

// react-railsに必要
import ReactRailsUJS from 'react_ujs'
const componentRequireContext = require.context('javascripts/admin/components', true)  // eslint-disable-line no-undef
ReactRailsUJS.useContext(componentRequireContext)
