import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

const SearchAddress = (props) => {
  axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  const handleClick = () => {
    const prefectures = document.getElementById(props.prefecture).options
    const city = document.getElementById(props.city)
    const street = document.getElementById(props.street)
    const api_token = document.getElementById('api_token') == null ? '' : document.getElementById('api_token').value

    const params = {
      search_zipcode: [document.getElementById(props.first_zipcode).value, document.getElementById(props.last_zipcode).value].join(''),
      api_token: api_token
    }

    axios.get(props.send_to, {
      params,
      withCredentials: true
    }).then(res => {
      if (prefectures[0].value == 1) {
        prefectures[res.data.code - 1].selected = true
      } else {
        prefectures[res.data.code].selected = true
      }
      city.value = res.data.city
      if (res.data.town) {
        street.value = res.data.town.replace(/[―−‐\-－]/g, 'ー')
      }
    }).catch(() => {
      alert('郵便番号に該当する住所がありません。')
    })
  }

  return (
    <button type='button' className='button is-primary' onClick={handleClick}>住所検索</button>
  )
}

SearchAddress.propTypes = {
  first_zipcode: PropTypes.string,
  last_zipcode: PropTypes.string,
  prefecture: PropTypes.string,
  city: PropTypes.string,
  street: PropTypes.string,
  send_to: PropTypes.string,
}

export default SearchAddress
