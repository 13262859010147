'use strict';

import $ from 'jquery';

$(function(){

  //----------------------------------------------
  // ドロップ時のCSS制御
  //----------------------------------------------
  $(document).on( "drop dragover dragenter", function(){
    // アップロードのドロップエリア以外にファイルをドロップしても何もしない様にする
    return false;
  });
  $(document).on( "dragover", ".draggable", function(){
    $(this).addClass("draghover");
  });
  $(document).on( "drop dragleave", ".draggable", function(){
    $(this).removeClass("draghover");
  });

  //----------------------------------------------
  // ファイル選択の直前に、前回のアップロードデータを消去
  // （消去しないと、同じファイルを続けてアップロードできない）
  //----------------------------------------------
  $(document).on( "click", ".upload-button-interface", function(){
    $(this).closest('label').find('input[type=file]').val('');
  } )

});
