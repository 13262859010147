'use strict';

import $ from 'jquery';

$(function(){

  //----------------------------------------------
  // ドロップダウンメニューの開閉CSS制御
  //----------------------------------------------
  $(document).on('click keydown change', '.dropdown', function(evt){
    $(this).addClass('is-active');
    evt.stopPropagation();
  });
  $(document).on('click', '.dropdown-menu', function(evt){
    $(this).closest('.dropdown').removeClass('is-active');
    evt.stopPropagation();
  });
  $(document).on('click', function(){
    $('.dropdown').removeClass('is-active');
  });

});
