"use strict";

//anchor hover
function hover() {
  var ua = navigator.userAgent.toLowerCase();
  var is_mobile = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('android') > -1;

  function hoverEvent() {
    this.classList.toggle('is-hover');
  }

  var touch_anchor = document.querySelectorAll('.is-touch a,.is-touch .icon');

  if (touch_anchor != null) {
    if (is_mobile) {
      for (var i = 0; i < touch_anchor.length; i++) {
        touch_anchor[i].addEventListener('touchstart', hoverEvent, false);
      }
    } else {
      for (var _i = 0; _i < touch_anchor.length; _i++) {
        touch_anchor[_i].addEventListener('mouseover', hoverEvent, false);
      }
    }
  }
}

hover(); //drawer menu

function drawer() {
  var drawer = document.getElementById('js-drawer-nav');
  var drawer_button = document.getElementById('js-btn-drawer');

  function activeEvent() {
    drawer.classList.toggle('is-active');
  }

  if (drawer != null) {
    drawer_button.addEventListener('click', activeEvent, false);
  }
}

drawer(); //header slim

function slimheader() {
  var header = document.getElementById('js-header-nav');
  var drawer = document.getElementById('js-drawer-nav');

  if (header != null) {
    window.onscroll = function () {
      var scroll_top = window.pageYOffset || document.documentElement.scrollTop;

      if (scroll_top > 90) {
        header.classList.add('is-slim');
        drawer.classList.add('is-slim');
      } else {
        header.classList.remove('is-slim');
        drawer.classList.remove('is-slim');
      }
    };
  }
}

slimheader(); //smooth link

function smoothlink() {
  var headH = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var interval = 10;
  var divisor = 8;
  var range = divisor / 2 + 1;
  var links = document.querySelectorAll('a[href^="#"]');

  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', function (e) {
      e.preventDefault();
      var toY;
      var nowY = window.pageYOffset;
      var href = e.target.getAttribute('href');
      var target = document.querySelector(href);
      var targetRect = target.getBoundingClientRect();
      var targetY = targetRect.top + nowY - headH;

      (function doScroll() {
        toY = nowY + Math.round((targetY - nowY) / divisor);
        window.scrollTo(0, toY);
        nowY = toY;

        if (document.body.clientHeight - window.innerHeight < toY) {
          window.scrollTo(0, document.body.clientHeight);
          return;
        }

        if (toY >= targetY + range || toY <= targetY - range) {
          window.setTimeout(doScroll, interval);
        } else {
          window.scrollTo(0, targetY);
        }
      })();
    });
  }
}

var headH = 24;
smoothlink(headH); // Modals

var rootEl = document.documentElement;
var $modals = getAll('.modal');
var $modalButtons = getAll('.modal-button');
var $modalCloses = getAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button');

if ($modalButtons.length > 0) {
  $modalButtons.forEach(function ($el) {
    $el.addEventListener('click', function () {
      var target = $el.dataset.target;
      openModal(target);
    });
  });
}

if ($modalCloses.length > 0) {
  $modalCloses.forEach(function ($el) {
    $el.addEventListener('click', function () {
      closeModals();
    });
  });
}

function openModal(target) {
  var $target = document.getElementById(target);
  rootEl.classList.add('is-clipped');
  $target.classList.add('is-active');
}

function closeModals() {
  rootEl.classList.remove('is-clipped');
  $modals.forEach(function ($el) {
    $el.classList.remove('is-active');
  });
}

document.addEventListener('keydown', function (event) {
  var e = event || window.event;

  if (e.keyCode === 27) {
    closeModals();
  }
}); // Functions

function getAll(selector) {
  var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;
  return Array.prototype.slice.call(parent.querySelectorAll(selector), 0);
}