import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

const SelectOrganization = (props) => {
  axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  const initialState = [{ organization_id: '', name: 'リストから選択' }]
  const third = initialState.concat(props.organizations3)
  const [fourth, setFourth] = useState(initialState)
  const [fifth, setFifth] = useState(initialState)
  const [sixth, setSixth] = useState(initialState)
  const [seventh, setSeventh] = useState(initialState)
  const [eighth, setEighth] = useState(initialState)

  const watchThird = (e) => {
    const params = {
      'search[organization]': e.target.value,
      'search[hierarchy]': 'third'
    }
    if (!e.target.value) {
      // 未選択の場合
      setFourth(initialState)
      setFifth(initialState)
      setSixth(initialState)
      setSeventh(initialState)
      setEighth(initialState)
      return
    } else {
      // 団体が選択された場合
      setFourth(initialState)
      setFifth(initialState)
      setSixth(initialState)
      setSeventh(initialState)
      setEighth(initialState)
      search_children(params)
    }
  }

  const watchFourth = (e) => {
    const params = {
      'search[organization]': e.target.value,
      'search[hierarchy]': 'fourth'
    }
    if (!e.target.value) {
      // 未選択の場合
      setFifth(initialState)
      setSixth(initialState)
      setSeventh(initialState)
      setEighth(initialState)
      return
    } else {
      // 団体が選択された場合
      setFifth(initialState)
      setSixth(initialState)
      setSeventh(initialState)
      setEighth(initialState)
      search_children(params)
    }
  }

  const watchFifth = (e) => {
    const params = {
      'search[organization]': e.target.value,
      'search[hierarchy]': 'fifth'
    }
    if (!e.target.value) {
      // 未選択の場合
      setSixth(initialState)
      setSeventh(initialState)
      setEighth(initialState)
      return
    } else {
      // 団体が選択された場合
      setSixth(initialState)
      setSeventh(initialState)
      setEighth(initialState)
      search_children(params)
    }
  }

  const watchSixth = (e) => {
    const params = {
      'search[organization]': e.target.value,
      'search[hierarchy]': 'sixth'
    }
    if (!e.target.value) {
      // 未選択の場合
      setSeventh(initialState)
      setEighth(initialState)
      return
    } else {
      // 団体が選択された場合
      setSeventh(initialState)
      setEighth(initialState)
      search_children(params)
    }
  }

  const watchSeventh = (e) => {
    const params = {
      'search[organization]': e.target.value,
      'search[hierarchy]': 'seventh'
    }

    if (!e.target.value) {
      // 未選択の場合
      setEighth(initialState)
      return
    } else {
      // 団体が選択された場合
      setEighth(initialState)
      search_children(params)
    }
  }

  const search_children = (params) => {
    axios.get(props.send_to, {
      params,
      withCredentials: true
    }).then(res => {
      const data = res.data
      switch (data.hierarchy) {
        case 'fourth':
          setFourth(initialState.concat(data.organizations))
          break
        case 'fifth':
          setFifth(initialState.concat(data.organizations))
          break
        case 'sixth':
          setSixth(initialState.concat(data.organizations))
          break
        case 'seventh':
          setSeventh(initialState.concat(data.organizations))
          break
        case 'eighth':
          setEighth(initialState.concat(data.organizations))
          break
      }
    }).catch(() => {
      alert('エラーが発生しました')
    })
  }

  return (
    <table className='table is-bordered is-fullwidth'>
      <tbody>
        <tr>
          <th className='is-width-20'>
            中央団体
            <span className='tag is-danger is-small require-tag'>必須</span>
          </th>
          <td>
            <div className='control'>
              <span className='select'>
                <select name='create_team[organization3]' id='create_team_organization3' onChange={watchThird}>
                  { third.map( (s, index) => <option key={index} value={s.organization_id}>{s.name}</option>) }
                </select>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <th className='is-width-20'>
            中央団体
            <span className='tag is-danger is-small require-tag'>必須</span>
          </th>
          <td>
            <div className='control'>
              <span className='select'>
                <select name='create_team[organization4]' id='create_team_organization4' onChange={watchFourth}>
                  { fourth.map( (s, index) => <option key={index} value={s.organization_id}>{s.name}</option>) }
                </select>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <th className='is-width-20'>
            中央団体/支部団体
            <span className='tag is-link is-small optional-tag'>任意</span>
          </th>
          <td>
            <div className='control'>
              <span className='select'>
                <select name='create_team[organization5]' id='create_team_organization5' onChange={watchFifth}>
                  { fifth.map( (t, index) => <option key={index} value={t.organization_id}>{t.name}</option>) }
                </select>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <th className='is-width-20'>
            支部団体
            <span className='tag is-link is-small optional-tag'>任意</span>
          </th>
          <td>
            <div className='control'>
              <span className='select'>
                <select name='create_team[organization6]' id='create_team_organization6' onChange={watchSixth}>
                  { sixth.map( (f, index) => <option key={index} value={f.organization_id}>{f.name}</option>) }
                </select>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <th className='is-width-20'>
            支部団体
            <span className='tag is-link is-small optional-tag'>任意</span>
          </th>
          <td>
            <div className='control'>
              <span className='select'>
                <select name='create_team[organization7]' id='create_team_organization7' onChange={watchSeventh}>
                  { seventh.map( (f, index) => <option key={index} value={f.organization_id}>{f.name}</option>)}
                </select>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <th className='is-width-20'>
            支部団体
            <span className='tag is-link is-small optional-tag'>任意</span>
          </th>
          <td>
            <div className='control'>
              <span className='select'>
                <select name='create_team[organization8]' id='create_team_organization8'>
                  { eighth.map( (f, index) => <option key={index} value={f.organization_id}>{f.name}</option>)}
                </select>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

SelectOrganization.propTypes = {
  organizations3: PropTypes.array,
  send_to: PropTypes.string
}

export default SelectOrganization
