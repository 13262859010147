'use strict';

import $ from 'jquery';

$(function(){

  // 押下されたボタンなどのクラス属性に is-disabled があったら動作させない
  $('input[type=submit],button,a').on('click', function(){
    if ( $(this).hasClass('is-disabled') ) return false;
  });

});
