'use strict';

import $ from 'jquery';
import { removeHasmnyForm } from '../javascripts/hasmany_form.js'

$(function(){

  //----------------------------------------------
  // 1対多フォームデータの行を自動削除する
  //----------------------------------------------
  $("body").on( "click", ".remove-hasmany-data", function(){
    return removeHasmnyForm( $(this).closest(".hasmany-data") );
  } );

  //----------------------------------------------
  // テンプレート下のデータを送信しない様に加工する
  //----------------------------------------------
  $("body").on("submit", "form", function () {
    let data_remote = $(this).attr("data-remote");
    if ('undefined' == typeof data_remote || 0 == data_remote.length) {
      $(".template").find("*[name]").remove();
    }
  } );

});
