import React, { useState } from 'react'
import PropTypes from 'prop-types'

const CopyForm = (props) => {
  const [firstGroup, setFirstGroup] = useState('')
  const [secondGroup, setSecondGroup] = useState('')
  const [thirdGroup, setThirdGroup] = useState('')
  const [fourthGroup, setFourthGroup] = useState('')

  const watchFirstGroup = (e) => {
    if (firstGroup == e.target.value) {
      resetSource('kantoku')
      setFirstGroup('')
    } else {
      setSource({ to: 'team_detail_kantoku', from: e.target.value })
      setFirstGroup(e.target.value)
    }
  }

  const watchSecondGroup = (e) => {
    if (secondGroup == e.target.value) {
      resetSource('tantou')
      setSecondGroup('')
    } else {
      singleCheckbox(e)
      setSource({ to: 'team_detail_tantou', from: e.target.value })
      setSecondGroup(e.target.value)
    }
  }

  const watchThirdGroup = (e) => {
    if (thirdGroup == e.target.value) {
      resetSource('delivery')
      setThirdGroup('')
    } else {
      singleCheckbox(e)
      setSource({ to: 'team_detail_delivery', from: e.target.value })
      setThirdGroup(e.target.value)
    }
  }

  const watchFourthGroup = (e) => {
    if (fourthGroup == e.target.value) {
      resetSource('doctor')
      setFourthGroup('')
    } else {
      singleCheckbox(e)
      setSource({ to: 'team_detail_doctor', from: e.target.value })
      setFourthGroup(e.target.value)
    }
  }

  const setSource = (args) => {
    switch(props.which_group) {
      case 'first':
        document.getElementById(`${args.to}_sei`).value = document.getElementById(`${args.from}_sei`).value
        document.getElementById(`${args.to}_mei`).value = document.getElementById(`${args.from}_mei`).value
        document.getElementById(`${args.to}_sei_kana`).value = document.getElementById(`${args.from}_sei_kana`).value
        document.getElementById(`${args.to}_mei_kana`).value = document.getElementById(`${args.from}_mei_kana`).value
        document.getElementById(`${args.to}_phone_number`).value = document.getElementById(`${args.from}_phone_number`).value
        break
      case 'second':
        document.getElementById(`${args.to}_sei`).value = document.getElementById(`${args.from}_sei`).value
        document.getElementById(`${args.to}_mei`).value = document.getElementById(`${args.from}_mei`).value
        document.getElementById(`${args.to}_sei_kana`).value = document.getElementById(`${args.from}_sei_kana`).value
        document.getElementById(`${args.to}_mei_kana`).value = document.getElementById(`${args.from}_mei_kana`).value
        document.getElementById(`${args.to}_phone_number`).value = document.getElementById(`${args.from}_phone_number`).value
        if (args.from == 'team_detail_daihyou') {
          document.getElementById(`${args.to}_position`).value = document.getElementById(`${args.from}_position`).value
        } else {
          document.getElementById(`${args.to}_position`).value = ''
        }
        break
      case 'third':
        document.getElementById(`${args.to}_first_zipcode`).value = document.getElementById(`${args.from}_first_zipcode`).value
        document.getElementById(`${args.to}_last_zipcode`).value = document.getElementById(`${args.from}_last_zipcode`).value
        document.getElementById(`${args.to}_prefecture_code`).value = document.getElementById(`${args.from}_prefecture_code`).value
        document.getElementById(`${args.to}_city`).value = document.getElementById(`${args.from}_city`).value
        document.getElementById(`${args.to}_street`).value = document.getElementById(`${args.from}_street`).value
        document.getElementById(`${args.to}_building`).value = document.getElementById(`${args.from}_building`).value
        document.getElementById(`${args.to}_phone_number`).value = document.getElementById(`${args.from}_phone_number`).value
        break
      case 'fourth':
        document.getElementById(`${args.to}_sei`).value = document.getElementById(`${args.from}_sei`).value
        document.getElementById(`${args.to}_mei`).value = document.getElementById(`${args.from}_mei`).value
        document.getElementById(`${args.to}_sei_kana`).value = document.getElementById(`${args.from}_sei_kana`).value
        document.getElementById(`${args.to}_mei_kana`).value = document.getElementById(`${args.from}_mei_kana`).value
        if (args.from == 'team_detail_daihyou' || args.from == 'team_detail_tantou' ) {
          document.getElementById(`${args.to}_position`).value = document.getElementById(`${args.from}_position`).value
        } else {
          document.getElementById(`${args.to}_position`).value = ''
        }
        break
    }
  }

  const resetSource = (arg) => {
    switch(props.which_group) {
      case 'first':
        document.getElementById(`team_detail_${arg}_sei`).value = props.team_detail[`${arg}_sei`]
        document.getElementById(`team_detail_${arg}_mei`).value = props.team_detail[`${arg}_mei`]
        document.getElementById(`team_detail_${arg}_sei_kana`).value = props.team_detail[`${arg}_sei_kana`]
        document.getElementById(`team_detail_${arg}_mei_kana`).value = props.team_detail[`${arg}_mei_kana`]
        document.getElementById(`team_detail_${arg}_phone_number`).value = props.team_detail[`${arg}_phone_number`]
        break
      case 'second':
        document.getElementById(`team_detail_${arg}_sei`).value = props.team_detail[`${arg}_sei`]
        document.getElementById(`team_detail_${arg}_mei`).value = props.team_detail[`${arg}_mei`]
        document.getElementById(`team_detail_${arg}_sei_kana`).value = props.team_detail[`${arg}_sei_kana`]
        document.getElementById(`team_detail_${arg}_mei_kana`).value = props.team_detail[`${arg}_mei_kana`]
        document.getElementById(`team_detail_${arg}_phone_number`).value = props.team_detail[`${arg}_phone_number`]
        document.getElementById(`team_detail_${arg}_position`).value = props.team_detail[`${arg}_position`]
        break
      case 'third':
        if (props.team_detail[`${arg}_zipcode`] == null) {
          document.getElementById(`team_detail_${arg}_first_zipcode`).value = ''
          document.getElementById(`team_detail_${arg}_last_zipcode`).value = ''
        } else {
          document.getElementById(`team_detail_${arg}_first_zipcode`).value = props.team_detail[`${arg}_zipcode`].slice(0, 3)
          document.getElementById(`team_detail_${arg}_last_zipcode`).value = props.team_detail[`${arg}_zipcode`].slice(3, 7)
        }
        document.getElementById(`team_detail_${arg}_prefecture_code`).value = props.team_detail[`${arg}_prefecture_code`] || ''
        document.getElementById(`team_detail_${arg}_city`).value = props.team_detail[`${arg}_city`]
        document.getElementById(`team_detail_${arg}_street`).value = props.team_detail[`${arg}_street`]
        document.getElementById(`team_detail_${arg}_building`).value = props.team_detail[`${arg}_building`]
        document.getElementById(`team_detail_${arg}_phone_number`).value = props.team_detail[`${arg}_phone_number`]
        break
      case 'fourth':
        document.getElementById(`team_detail_${arg}_sei`).value = props.team_detail[`${arg}_sei`]
        document.getElementById(`team_detail_${arg}_mei`).value = props.team_detail[`${arg}_mei`]
        document.getElementById(`team_detail_${arg}_sei_kana`).value = props.team_detail[`${arg}_sei_kana`]
        document.getElementById(`team_detail_${arg}_mei_kana`).value = props.team_detail[`${arg}_mei_kana`]
        document.getElementById(`team_detail_${arg}_position`).value = props.team_detail[`${arg}_position`]
        break
    }
  }

  const singleCheckbox = (e) => {
    document.getElementsByName(e.target.name).forEach((element) => {
      element.checked = false
    })
    e.target.checked = true
  }

  switch(props.which_group) {
    case 'first':
      return (
        <div className="columns is-multiline">
          <div className="column is-2">
            <label htmlFor="first-daihyou" className="checkbox is-primary">
              <input type="checkbox" name="first_group" value='team_detail_daihyou' id="first-daihyou" onClick={watchFirstGroup} />
              <span className="item">代表者と同じ</span>
            </label>
          </div>
        </div>
      )

    case 'second':
      return (
        <div className="columns is-multiline">
          <div className="column is-2">
            <label htmlFor="second-daihyou" className="checkbox is-primary">
              <input type="checkbox" name="second_group" value='team_detail_daihyou' id="second-daihyou" onClick={watchSecondGroup} />
              <span className="item">代表者と同じ</span>
            </label>
          </div>
          <div className="column is-2">
            <label htmlFor="second-kantoku" className="checkbox is-primary">
              <input type="checkbox" name="second_group" value='team_detail_kantoku' id="second-kantoku" onClick={watchSecondGroup} />
              <span className="item">監督と同じ</span>
            </label>
          </div>
        </div>
      )

    case 'third':
      return (
        <div className="columns is-multiline">
          <div className="column is-2">
            <label htmlFor="third-team-address" className="checkbox is-primary">
              <input type="checkbox" name="third_group" value='team_detail' id="third-team-address" onClick={watchThirdGroup} />
              <span className="item">チーム住所情報と同じ</span>
            </label>
          </div>
        </div>
      )

    case 'fourth':
      return (
        <div className="columns is-multiline">
          <div className="column is-2">
            <label htmlFor="fourth-daiyou" className="checkbox is-primary">
              <input type="checkbox" name="fourth_group" value='team_detail_daihyou' id="fourth-daiyou" onClick={watchFourthGroup} />
              <span className="item">代表者と同じ</span>
            </label>
          </div>
          <div className="column is-2">
            <label htmlFor="fourth-kantoku" className="checkbox is-primary">
              <input type="checkbox" name="fourth_group" value='team_detail_kantoku' id="fourth-kantoku" onClick={watchFourthGroup} />
              <span className="item">監督と同じ</span>
            </label>
          </div>
          <div className="column is-2">
            <label htmlFor="fourth-tantou" className="checkbox is-primary">
              <input type="checkbox" name="fourth_group" value='team_detail_tantou' id="fourth-tantou" onClick={watchFourthGroup} />
              <span className="item">担当者と同じ</span>
            </label>
          </div>
        </div>
      )
  }
}

CopyForm.propTypes = {
  which_group: PropTypes.string,
  team_detail: PropTypes.object,
};

export default CopyForm
