'use strict';

import $ from 'jquery';

$(function(){

  //----------------------------------------------
  // モーダルの閉じるボタン
  //----------------------------------------------
  $(document).on( 'click', '.modal [aria-label=close]', function(){
    $(this).closest('.modal').removeClass('is-active');
    return false;
  });

  //----------------------------------------------
  // 共通確認ダイアログ
  //----------------------------------------------
  $('a[data-confirm-message],input[data-confirm-message],button[data-confirm-message]').on('click', function(){

    // 押下されたボタンなどのクラス属性に is-disabled があったら動作させない
    if ( $(this).hasClass('is-disabled') ) return false;
    // モーダル表示
    var modal = $('#common-confirm-modal')
    var button = modal.find('.button[aria-label=ok]');
    var title = modal.find('.modal-card-title');
    var title_message = $(this).attr('data-confirm-title');
    if ( null != title_message && 0 < title_message.length ) title.text( title_message );
    modal.find('.modal-message').text( $(this).attr('data-confirm-message') )
    modal.addClass('is-active');
    // 同じモーダルを同一画面で使い回す可能性があるので「はい」ボタン押下イベントを再設定
    button.off('click');
    button.on("click",function(){
      // 二重クリック防止
      button.prop('disabled', true);
      // 「はい」押下されたら、イベントトリガになっているセレクタを外してリンクをクリックすれば元々の動作が発火する
      var self = $(this);
      self.removeAttr('data-confirm-message');
      self.off('click');
      // 非同期処理の中からクリックしたことにしないと、前述のDOM変更が反映される前にイベント発火され、イベント呼出が循環になってしまう。
      setTimeout(function(){
        this.click();
      }.bind(this),1);
    }.bind(this));
    return false;
  });

  //----------------------------------------------
  // モーダルが開いてしまうためイベント伝播の抑制
  //----------------------------------------------
  $("input,select").on("keydown", function(e){
    if ( 0 < $(this).closest('.mp-lead').length && 13 == e.keyCode ) return false;
  });

  //----------------------------------------------
  // モーダルの自動起動
  //----------------------------------------------
  $('a[data-modal-for],input[data-modal-for],button[data-modal-for]').on('click', function(){
    // 押下されたボタンなどのクラス属性に is-disabled があったら動作させない
    if ( $(this).hasClass('is-disabled') ) return false;
    // モーダル表示
    var modal = $('#' + $(this).attr('data-modal-for') + '.modal');
    modal.addClass('is-active');
    return false;
  });
});
