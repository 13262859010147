'use strict';

import $ from 'jquery';

//--------------------------------------
// 要素から指定属性の情報を取得する
//   本メソッドは、ドロップダウンリスト等の選択をトリガに1対多フォームを作成する場合に、
//   予めフォーム追加のトリガとなる要素に、フォーム値の初期値となる値を、data-xxx形式の属性値として設定しておきたい場合に、
//   その属性値情報を取得し、appendHasmanyFormByTemplate()のparamsに引き渡す初期値情報を生成するためのユーティリティ関数です
//
//   引数)
//     element   : (jquery object) 属性値情報を取得したい対象要素
//     key names : (String) 取得したい属性値（可変長で複数指定可）
//
//   ex)
//     <div id="example" data-id="1" data-foo="2" data-bar="3" >...</div>
//
//     getParamsByElement( $("#example"), "id", "foo", "bar" );
//     戻り値：{ id:"1", foo:"2", bar:"3" }
//--------------------------------------
export const getParamsByElement = function( element ){   // eslint-disable-line
  // 可変長引数からデータ取得したいキー名を取得
  let keys = [];
  for ( let i=1; i<arguments.length; i++ ) {
    if ( arguments[i] instanceof Array ) {
      keys = keys.concat( arguments[i] );
    }
    else {
      keys.push( arguments[i] )
    }
  }
  // 要素のdata属性からデータ取得
  let results = {};
  for ( let i=0; i<keys.length; i++ ) {
    results[ keys[i] ] = element.data( keys[i] ) ||
                         element.find('*[data-'+keys[i]+']').first().data( keys[i] ) ||
                         element.find('.data-'+keys[i]).first().val() ||
                         element.find('.data-'+keys[i]).first().text();
  }
  return results
}

//--------------------------------------
// 1対多要素のフォーム追加
//   テンプレートとなる要素のフォームに、指定の初期値を設定の上で
//   指定の要素下に追加して、1対多フォームを生成します。
//   参照：https://github.com/sis-dev03/NPB/wiki/1%E5%AF%BE%E5%A4%9A%E3%83%A2%E3%83%87%E3%83%AB%E3%81%AE%E5%8B%95%E7%9A%84%E7%B7%A8%E9%9B%86%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6
//
//   引数)
//     dest_parent_element : (1対多フォームの親要素)
//     template_element    : (テンプレート要素)
//     params              : (追加するフォームの初期値)
//
//   ex)
//     <div id="examples">...(略)...</div>                                <- 1対多フォームの表示領域
//     <div id="example-template" class="template is-hidden">             <- フォームのテンプレを包含する要素。クラス属性値にtemplateをつけること
//       <div class="hasmany-data">                                       <- クラス属性にhasmany-dataをつけること
//         <input type="hidden" name="model[submodel_attributes][0][_destroy]" class="data-destroy" />         <- 属性値（_destroy）に対応するクラス属性値、data-destroyをつけること
//         <input type="hidden" name="model[submodel_attributes][0][id]"       class="data-id primary-key" />  <- 属性値（id）に対応するクラス属性値、data-idをつけること。また主キーであることを示す、primary-keyをつけること
//         <input type="text"   name="model[submodel_attributes][0][name]"     class="data-name" />            <- 属性値（name）に対応するクラス属性値、data-nameをつけること
//         <button class="remove-hasmany-data">削除</button>                     <- クラス属性値、remove-hasmany-dataをつけると、この要素がクリックされた時に、自動的に1対多フォームから削除処理が行われる
//       </div>
//     </div>
//
//     appendHasmanyFormByTemplate(
//       $("#examples"),                // 追加先の要素
//       $("#template .hasmany-data"),  // 追加元の要素（テンプレート）
//       { id:"1", name:"foo" } );      // 初期値
//     以下の通りDOMが変更される
//
//     <div id="examples">
//       <div class="hasmany-data">...(略)...</div>   <- 既存の1対多フォーム
//       <div class="hasmany-data">...(略)...</div>   <- 既存の1対多フォーム
//       <div class="hasmany-data">                   <- テンプレートをコピーして追加された1対多フォーム
//         <input type="hidden" name="model[submodel_attributes][2][_destroy]" value=""    class="data-destroy"/>
//         <input type="hidden" name="model[submodel_attributes][2][id]"       value="1"   class="data-id primary-key" />
//         <input type="text"   name="model[submodel_attributes][2][name]"     value="foo" class="data-name" />
//         <button class="remove-hasmany-data">削除</button>
//       </div>
//     </div>
//     <div id="template" style="display:none">...(略)...</div>
//
//--------------------------------------
export const appendHasmanyFormByTemplate = function( dest_parent_element, template_element, params ){
  // フォームに追加する
  dest_parent_element.append( copied_template(dest_parent_element, template_element, params) );
  normalizeHasmnyFormIndexNumber(dest_parent_element);
}

export const appendHasmanyFormByEditTemplate = function( dest_parent_element, template_element, params ){
  // 編集フォームに追加する
  dest_parent_element.append( copied_edit_template(dest_parent_element, template_element, params) );
  normalizeHasmnyFormIndexNumber(dest_parent_element);
}

//--------------------------------------
// 1対多要素のフォーム先頭追加
//--------------------------------------
export const prependHasmanyFormByTemplate = function( dest_parent_element, template_element, params ){
  // フォームに追加する
  dest_parent_element.prepend( copied_template(dest_parent_element, template_element, params) );
  normalizeHasmnyFormIndexNumber(dest_parent_element);
}

//--------------------------------------
// 1対多要素のref_element直前に新しい要素を追加
//--------------------------------------
export const insertHasmanyFormByTemplate = function( ref_element, dest_parent_element, template_element, params ){
  const new_element = copied_template(dest_parent_element, template_element, params)

  new_element.insertBefore(ref_element);
  normalizeHasmnyFormIndexNumber(dest_parent_element);
}

//--------------------------------------
// 指定テンプレートの追加用コピーを生成
//--------------------------------------
export const normalizeHasmnyFormIndexNumber = function( dest_parent_element ){
  let index = 0;
  dest_parent_element.find('.hasmany-data').each(function(){
    $(this).find('*[name]').each(function(){
      formNameIndex( $(this), index );
    })
    index++;
  } );
}

//--------------------------------------
// 指定属性のフォームパラメータ上の配列番号を取得・設定する
//--------------------------------------
export const paramsIndexByAttributeName = function( element, attribute_name, new_index ){
  let regexp = new RegExp( "\\[" + attribute_name + "\\]\\[([0-9]+)\\]", "i" );
  let matched = element.attr( "name" ).match( regexp );
  if ( matched ) {
    // インデックス番号を取得
    let index = parseInt( matched[1] );
    // 新しいインデックス番号の指定がある場合には変更するz
    if ( "undefined" != typeof new_index ) {
      let new_name = matched[0];
      new_name = new_name.replace( /\[[0-9]+\]/, "[" + new_index + "]" );
      new_name = element.attr( "name" ).replace( regexp, new_name );
      element.attr( "name", new_name );
    }
    return index;
  }
  return null;
}

//--------------------------------------
// １対多フォームを削除する
//--------------------------------------
export const removeHasmnyForm = function( hasmany_data ){
  if ( !hasmany_data.hasClass('hasmany-data') ) return false;

  let primary_key = hasmany_data.find(".primary-key");
  // 主キー設定あり
  if ( 0 < primary_key.length && 0 < primary_key.val().length ) {
    // DB追加済みなので、削除フラグを送信する
    let destroy_elm = hasmany_data.find(".data-destroy");
    if ( 0 < destroy_elm.length ) {
      destroy_elm.val("true");
    }
    else {
      destroy_elm = primary_key.clone();
      let name = destroy_elm.attr( "name" );
      destroy_elm.attr( "name", name.replace( /\[[a-zA-Z0-9\-_]+\]$/, "[_destroy]" ) )
      destroy_elm.val("true");
      destroy_elm.removeClass("primary-key");
      destroy_elm.addClass("data-destroy")
      destroy_elm.attr("id", "");
      primary_key.after( destroy_elm );
    }
    hasmany_data.hide();
  }
  // 主キー設定なし
  else {
    // まだDB追加前なので、そのままDOMを削除する
    hasmany_data.remove();
  }
  $("body").trigger("remove-hasmany-data");
  return false;
}


//--------------------------------------
// (Private関数) XSSサニタイズ
//--------------------------------------
function escapeHtml( val ) {
  return $('<div />').text(val).html().replace(/\\n/g, "<br>").replace( /"/ig, '&quot;' );
}

//--------------------------------------
// (Private関数) 指定要素下にパラメータを設定する
//--------------------------------------
function setFormParams( elements, params ) {
  // 各パラメータキーに対して、"data-[key name]"のクラス属性を持つ要素に対して、値を設定する
  for ( let key in params ) {
    elements.find( ".data-" + key ).each( function(){
      let element = $(this);
      switch( element.prop("tagName").toUpperCase() ){
      // フォーム要素ならば、その値として設定する
      case "INPUT":
        switch ( element.attr( "type" ).toLowerCase() ) {
        case "checkbox":
        case "radio":
          if ( element.attr( "value" ) == params[key] ) {
            element.prop( "checked", true );
          }
          break;

        default:
          element.val( params[key] );
        }
        break;

      case "TEXTAREA":
      case "BUTTON":
      case "SELECT":
        element.val( params[key] );
        break;

      // フォーム要素以外ならば、その子要素のテキストとして設定する
      default:
        element.empty();
        element.append( escapeHtml( params[key] ) );
        break;
      }
    } );
    elements.find( "[data-" + key + "]" ).each( function(){
      $(this).attr( 'data-' + key, params[key] );
    } );
    if ( 'undefined' != typeof elements.attr( 'data-' + key ) ) {
      elements.attr( 'data-' + key, params[key] );
    }
  }
}

//--------------------------------------
// (Private関数) フォーム要素のname属性のindex値を取得・設定する
//--------------------------------------
function formNameIndex( element, new_index ) {
  let regexp = new RegExp( "_attributes\\]\\[([0-9]+)\\](\\[_?[a-zA-Z][a-zA-Z0-9\\-_]+\\])+$", "i" );
  let matched = element.attr( "name" ).match( regexp );
  if ( matched ) {
    // インデックス番号を取得
    let index = parseInt( matched[1] );
    // 新しいインデックス番号の指定がある場合には変更するz
    if ( "undefined" != typeof new_index ) {
      let new_name = matched[0];
      new_name = new_name.replace( /\[[0-9]+\]/, "[" + new_index + "]" );
      new_name = element.attr( "name" ).replace( regexp, new_name );
      element.attr( "name", new_name );
    }
    return index;
  }
  return null;
}

//--------------------------------------
// 指定テンプレートの追加用コピーを生成
//--------------------------------------
function copied_template( dest_parent_element, template_element, params ) { // eslint-disable-line

  // テンプレートから要素をコピーする
  let copied_temp = template_element.clone();
  copied_temp.show();
  copied_temp.removeClass("hide");

  // 要素に値を設定する
  setFormParams( copied_temp, params );

  // 主キーは空にする
  copied_temp.find(".primary-key").val("");

  return copied_temp;
}

//--------------------------------------
// 指定テンプレートの追加用コピーを生成(編集用に主キーを空にしない)
//--------------------------------------
function copied_edit_template( dest_parent_element, template_element, params ) { // eslint-disable-line

  // テンプレートから要素をコピーする
  let copied_temp = template_element.clone();
  copied_temp.show();
  copied_temp.removeClass("hide");

  // 要素に値を設定する
  setFormParams( copied_temp, params );

  return copied_temp;
}